import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Container, Table } from "reactstrap"

import "react-multi-carousel/lib/styles.css"

import { Link, useNavigate } from "react-router-dom"

import jwtDecode from "jwt-decode"
//i18n
import { withTranslation } from "react-i18next"
//pass request back end
import axios from "axios";
import moment from "moment";
import Loader from "react-spinner-loader";
import { SECURITY_KEY, BASE_URL } from "../../../global";
const MastryScore = props => {


    //Dedoce user details start
    function decodeAccessToken(token) {
      try {
        const decoded = jwtDecode(token)
        return { success: true, data: decoded }
      } catch (error) {
        return { success: false, error: error.message }
      }
    }
  
    const [username, setusername] = useState(null)
  
    useEffect(() => {
      if (localStorage.getItem("authStudentUser")) {
        const obj = JSON.parse(localStorage.getItem("authStudentUser"))
        const jwtdecode = decodeAccessToken(obj)
  
       // console.log(jwtdecode.data)
  
        setusername(jwtdecode.data)
        //}
      }
    }, [props.success])

  const [loader, setLoader] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(2);

  const [masryScoreData, setmasryScoreData] = useState([]);

  const [highMasryScoreData, setHighmasryScoreData] = useState([]);
  const [midMasryScoreData, setMedmasryScoreData] = useState([]);
  const [lowMasryScoreData, setLowmasryScoreData] = useState([]);

//subject start
  // const subjectOptions = [
  //   { subject_name: 'Mathematics', subject_id: 2 },
  //   { subject_name: 'English', subject_id: 1 },
  //   // { subject_name: 'Others', subject_id: 3 }
  // ];

  const [subjectOptions, setSubjectOption] = useState([
    { subject_name: "Mathematics", subject_id: 2 },
    { subject_name: "English", subject_id: 1 },
    // { subject_name: 'Others', subject_id: 3 }
  ]);
  const authUserSubject = JSON.parse(localStorage.getItem("subject")) || null
  useEffect(() => {
    if (!authUserSubject || authUserSubject.trim() === '') {
      setSubjectOption([
        { subject_name: "Mathematics", subject_id: 2 },
        { subject_name: "English", subject_id: 1 },
      ]);
      if (selectedSubject !== 2) {
        setSelectedSubject(2);
      }
    } else {
      let subjectIdsArray;
        if (authUserSubject.includes(',')) {
          subjectIdsArray = authUserSubject.split(',');
        } else {
          // Handle case when authUserSubject is a single ID without comma
          subjectIdsArray = [authUserSubject];
        }


      if (subjectIdsArray.length === 2 && subjectIdsArray.includes('1') && subjectIdsArray.includes('2')) {
        setSubjectOption([
          { subject_name: "Mathematics", subject_id: 2 },
          { subject_name: "English", subject_id: 1 },
        ])
        if (selectedSubject !== 2) {
          setSelectedSubject(2);
        }
      } else if (subjectIdsArray.length === 1 && subjectIdsArray.includes('1')) {
        setSubjectOption([
          { subject_name: "English", subject_id: 1 },
        ]);
        if (selectedSubject !== 1) {
          setSelectedSubject(1);
        }
      } else if (subjectIdsArray.length === 1 && subjectIdsArray.includes('2')) {
        setSubjectOption([
          { subject_name: "Mathematics", subject_id: 2 },
        ]);
        if (selectedSubject !== 2) {
          setSelectedSubject(2);
        }
      } else {
        setSubjectOption([
          { subject_name: "Mathematics", subject_id: 2 },
          { subject_name: "English", subject_id: 1 },
        ]);
        if (selectedSubject !== 2) {
          setSelectedSubject(2);
        }
      }
    }
    }, [authUserSubject])
  

  const handleSelectSubject = (subject) => {
    setSelectedSubject(subject);
  };

  useEffect(() => {
    fetchMastryScoreData();
   
  }, [selectedSubject]);




  const authUser = JSON.parse(localStorage.getItem('authStudentUser')) || null;

  const navigate = useNavigate();
//subject end


const fetchMastryScoreData = async () => {
  if (authUser && authUser != "" && authUser != null) {
   setLoader(true);
    axios.defaults.headers.common['Authorization'] = `Bearer ${authUser}`
    axios.post(BASE_URL+'masteryScore/mastryScoreData',{
      subject_id: selectedSubject
    }).then(responseMastryScore => {
      // Assuming the API response is an array of objects with 'id' and 'value' properties
      //console.log(responseMastryScore);
      if(responseMastryScore.data.length > 0){
        setmasryScoreData(responseMastryScore.data);
        const filteredHighData = responseMastryScore.data.filter(item => parseInt(item.adjusted_mastery_score) >= 70);
        //console.log(filteredHighData);
        let HighDataASC = filteredHighData.sort((a, b) => b.adjusted_mastery_score - a.adjusted_mastery_score);
        setHighmasryScoreData(HighDataASC);
        const filteredMedData = responseMastryScore.data.filter(item => parseInt(item.adjusted_mastery_score) < 70 && parseInt(item.adjusted_mastery_score) >= 50);
        //console.log(filteredMedData);
        let MedDataASC = filteredMedData.sort((a, b) => b.adjusted_mastery_score - a.adjusted_mastery_score);
        setMedmasryScoreData(MedDataASC);
        const filteredLowData = responseMastryScore.data.filter(item => parseInt(item.adjusted_mastery_score) < 50);
        let LowDataASC = filteredLowData.sort((a, b) => b.adjusted_mastery_score - a.adjusted_mastery_score );
        setLowmasryScoreData(LowDataASC);
      }else{
        setmasryScoreData([]);
      }
     
      setLoader(false);
    }).catch(error => {
      setLoader(false);
        // console.log(error.response.data.error)
        if (error.response.status === 403 && error.response.data.error === 'jwt expired') {
          localStorage.clear();
          navigate("/login");
          setLoader(false);
        }

      });

  }
  else {
    setLoader(false);
    navigate("/login");
  }


}







const selectedSubjectName = subjectOptions.find(
  (subject) => subject.subject_id === selectedSubject
)?.subject_name;

return (
<React.Fragment>
<Loader
        show={loader}
        type="body"
      // stack="vertical"
      // message="Loading Data"
      />
  <div className="page-wrapper">
    <div className="cs-pg-header bg-dark-blue py-3 pb-5">
      <Container className="custom-wt">
        <div className="">
          <div className="mt-0">
            <Link className="text-white font-size-14" to={"/"}> {" "}
                  <span>
                    <i className=" fas fa-chevron-left me-1"></i>
            </span>{" "}
            BACK TO DASHBOARD
            </Link>
          </div>
          <div className="pg-title">Mastery Scores</div>
        </div>
      </Container>
    </div>
    <Container className="custom-wt">

      <div className="page-inner_section mt-5 pt-2">
        <div className="pd-homework-section assessment-width-hold pb-100">
          <div className="page-header mb-4">

            <div className="pg-info">
              <ul className="pg-tab list-unstyled mb-0 d-flex align-items-center">
                <li>SUBJECT</li>
                {subjectOptions.map((subject) => (// Map over each subject in subjectOptions 
                    <li
                      key={subject} // Set a unique key for each list item
                      className={selectedSubject === subject.subject_id ? "active" : ""} // Set class to "active" if selectedSubject matches the current subject 
                      onClick={() => handleSelectSubject(subject.subject_id)} // Set up click event handler to call handleSelectSubject with the current subject 
                    >
                      {selectedSubject === subject.subject_id && ( // Conditionally render a checkmark if selectedSubject matches the current subject 
                        <span>
                          <i className="fas fa-check"></i>
                        </span>
                      )} {" "} {subject.subject_name}
                    </li>
                  ))}
               
              </ul>
            </div>
          </div>
          <div className="hw-list mt-4">
            <ul className="list-unstyled mb-0">
              <li className="justify-content-between">
                <p className="mb-0 text-uppercase luminous-wht">
                  <span className="text-warning fw-semibold font-size-12 me-1">{selectedSubjectName}</span>
                </p>
                <div className="d-flex align-items-start pb-4">
                  <div className="summary-child-left me-5">
                    <h3 className="bigger-ft mb-0">Mastery Scores</h3>
                    <div className="font-size-16 fw-bold text-dark d-flex align-items-center gap-2 mt-0">
                      <span className="fw-light">For</span>{username
                    ? username.st_first_name
                      ? username.st_first_name + " " + username.st_surname
                      : ""
                    : ""}
                    </div>
                  </div>
                </div>
                <div className="horizontal-divider"></div>
                <div className="mst-ct1">
                  <h4 className="mt-3 font-size-20 fw-bold text-dark mb-1">
                    🏆 Topics with High Mastery (More than 70)
                  </h4>
                  <p className="font-size-18 mb-0">
                    You're a champion at these topics! Keep up the good work.
                  </p>
                  <div className="table-responsive mt-4">
                    <Table className="table custom-table tb-custom-pd">
                      <thead>
                        <tr>
                          <th width="5%" className="text-center">
                            Rank
                          </th>
                          <th width="30%">Topic</th>
                          {/* <th width="5%" className="text-center">
                           Total Qns
                          </th> */}
                          <th width="5%" className="text-center">
                           Qns
                          </th>
                          {/* <th width="5%" className="text-center">
                           1st Attempt Correct Qns
                          </th>
                          <th width="10%" className="text-center">
                           % Correct
                          </th> */}
                         {/* <th width="10%" className="text-center">
                          Weighted Correct
                          </th>
                          <th width="10%" className="text-center">
                           Scaling Factor
                          </th> */}
                          <th width="10%" className="text-center">
                            Score
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        highMasryScoreData.length === 0 ? (
                          // Render a single row with "No data" if masryScoreData is empty
                          <tr>
                            <td colSpan="4" className="text-center font-size-18 fw-medium">No data yet</td>
                          </tr>
                        ) : (
                          // Render table rows if masryScoreData is not empty
                          highMasryScoreData.map((msdata, index) => (
                            <tr key={index}>
                              <td className="text-center font-size-18 fw-medium">{index + 1}</td>
                              <td className="font-size-18 fw-bold text-dark">{msdata.topic_name}</td>
                              {/* <td className="text-center font-size-18 text-dark">{msdata.total_questions}</td> */}
                              <td className="text-center font-size-18 text-dark">{msdata.question_attended}</td>
                              {/* <td className="text-center font-size-18 text-dark">{msdata.total_correct_question}</td>
                              <td className="text-center font-size-18 text-dark">{msdata.percentage_correct}</td> */}
                              {/* <td className="text-center font-size-18 text-dark">{msdata.weighted_correct_answer}</td>
                              <td className="text-center font-size-18 text-dark">{msdata.scaling_factor}</td> */}
                              <td className="text-center font-size-18 fw-bold text-dark">{msdata.adjusted_mastery_score}</td>
                            </tr>
                          ))
                        )
                      }


                   
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="horizontal-divider"></div>
                <div className="mst-ct1">
                  <h4 className="mt-3 font-size-20 fw-bold text-dark mb-1">
                    👌 Topics with Medium Mastery (50-70)
                  </h4>
                  <p className="font-size-18 mb-0">
                    You'll need more practice with these topics! Your tutor is here if you need help!
                  </p>
                  <div className="table-responsive mt-4">
                    <Table className="table custom-table tb-custom-pd">
                      <thead>
                        <tr>
                          <th width="5%" className="text-center">
                            Rank
                          </th>
                          <th width="30%">Topic</th>
                          {/* <th width="5%" className="text-center">
                           Total Qns
                          </th> */}
                          <th width="5%" className="text-center">
                            Qns
                          </th>
                          {/* <th width="10%" className="text-center">
                          1st Attempt Correct Qns
                          </th>
                          <th width="10%" className="text-center">
                           % Correct
                          </th> */}
                          {/* <th width="10%" className="text-center">
                          Weighted Correct
                          </th>
                          <th width="10%" className="text-center">
                           Scaling Factor
                          </th> */}
                          <th width="10%" className="text-center">
                            Score
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        midMasryScoreData.length === 0 ? (
                          // Render a single row with "No data" if masryScoreData is empty
                          <tr>
                            <td colSpan="4" className="text-center font-size-18 fw-medium">No data yet</td>
                          </tr>
                        ) : (
                          // Render table rows if masryScoreData is not empty
                          midMasryScoreData.map((msdata, index) => (
                            <tr key={index}>
                              <td className="text-center font-size-18 fw-medium">{index + 1}</td>
                              <td className="font-size-18 fw-bold text-dark">{msdata.topic_name}</td>
                              {/* <td className="text-center font-size-18 text-dark">{msdata.total_questions}</td> */}
                              <td className="text-center font-size-18 text-dark">{msdata.question_attended}</td>
                              {/* <td className="text-center font-size-18 text-dark">{msdata.total_correct_question}</td> */}
                              {/* <td className="text-center font-size-18 text-dark">{msdata.percentage_correct}</td> */}
                              {/* <td className="text-center font-size-18 text-dark">{msdata.weighted_correct_answer}</td>
                              <td className="text-center font-size-18 text-dark">{msdata.scaling_factor}</td> */}
                              <td className="text-center font-size-18 fw-bold text-dark">{msdata.adjusted_mastery_score}</td>
                            </tr>
                          ))
                        )
                      }
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="horizontal-divider"></div>
                <div className="mst-ct1">
                  <h4 className="mt-3 font-size-20 fw-bold text-dark mb-1">
                    💪 Topics with Low Mastery (Less than 50)
                  </h4>
                  <p className="font-size-18 mb-0">
                    Watch more videos on these topics to understand the concepts better!
                  </p>
                  <div className="table-responsive mt-4">
                    <Table className="table custom-table tb-custom-pd">
                      <thead>
                        <tr>
                          <th width="5%" className="text-center">
                            Rank
                          </th>
                          <th width="30%">Topic</th>
                          {/* <th width="5%" className="text-center">
                           Total Qns
                          </th> */}
                          <th width="5%" className="text-center">
                            Qns
                          </th>
                          {/* <th width="5%" className="text-center">
                          1st Attempt Correct Qns
                          </th>
                          <th width="10%" className="text-center">
                           % Correct
                          </th>
                          <th width="10%" className="text-center">
                          Weighted Correct
                          </th>
                          <th width="10%" className="text-center">
                           Scaling Factor
                          </th> */}
                          <th width="10%" className="text-center">
                            Score
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        lowMasryScoreData.length === 0 ? (
                          // Render a single row with "No data" if masryScoreData is empty
                          <tr>
                            <td colSpan="4" className="text-center font-size-18 fw-medium">No data yet</td>
                          </tr>
                        ) : (
                          // Render table rows if masryScoreData is not empty
                          lowMasryScoreData.map((msdata, index) => (
                            <tr key={index}>
                              <td className="text-center font-size-18 fw-medium">{index + 1}</td>
                              <td className="font-size-18 fw-bold text-dark">{msdata.topic_name}</td>
                              {/* <td className="text-center font-size-18 text-dark">{msdata.total_questions}</td> */}
                              <td className="text-center font-size-18 text-dark">{msdata.question_attended}</td>
                              {/* <td className="text-center font-size-18 text-dark">{msdata.total_correct_question}</td>
                              <td className="text-center font-size-18 text-dark">{msdata.percentage_correct}</td>
                              <td className="text-center font-size-18 text-dark">{msdata.weighted_correct_answer}</td>
                              <td className="text-center font-size-18 text-dark">{msdata.scaling_factor}</td> */}
                              <td className="text-center font-size-18 fw-bold text-dark">{msdata.adjusted_mastery_score}</td>
                            </tr>
                          ))
                        )
                      }
                      </tbody>
                    </Table>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  </div>
</React.Fragment>
)
}

MastryScore.propTypes = {
t: PropTypes.any,
}

export default withTranslation()(MastryScore)